.banho {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 5rem 0 0 0;
}

.banho__atividades__imagem {
    border-radius: 1rem;
}

.banho__imagem {
    width: 4.5rem;
}

.banho__atividades__swiper {
    width: 32.5rem;
    margin: 2rem 0 0 0;
    /* margin: 0 5rem 0 0; */
}

.banho__lugar {
    font-size: 2rem;
    font-family: "Rubik", sans-serif;
}

.banho__divisao {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 4rem 0 0 0;
}

.banho__infos {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    max-width: 42rem;
}

.banho__info {
    text-align: center;
    margin: 1.5rem;
    max-width: 15rem;
}

.banho__linha {
    width: 2px;
    height: 2rem;
    border-radius: 5rem;
    background: rgb(135, 160, 132);
}

.banho__titulo {
    font-weight: 700;
    margin: 1rem 0;
    font-size: 1.15rem;
    color: #1a212f;
    font-family: "Rubik", sans-serif;
}

.banho__subtitulo {
    color: #1a212f;
    font-family: "Lato", sans-serif;
}

@media screen and (max-width: 1000px) {
    .banho__divisao {
        align-items: center;
        flex-direction: column;
    } 

    .banho__infos {
       flex-direction: column;
       align-items: center;
       justify-content: center;
        
    }

    .banho__atividades__swiper {
        width: 22.5rem;
        margin: 2rem 0 0 0;
    }
}
