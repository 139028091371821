.consultar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    max-width: 52.5rem;
    padding: 1.5rem 3.5rem;
    margin: 7rem auto 5rem;
    border-radius: 1rem;
    background: rgb(135, 160, 132);
    background: linear-gradient(343deg, rgba(142,160,132,1) 0%, rgba(183,230,185,1) 100%);
}

.consultar__title {
    font-size: 2em;
    font-family: "Rubik", sans-serif;
    color: #1a212f;
}

.consultar__subtitle {
    max-width: 33.85rem;
    font-size: 1.05rem;
    font-weight: 400;
    margin: 1.25rem 0;
    font-family: "Lato", sans-serif;
    color: #1a212f;
}

@media screen and (max-width: 1000px) {
    .consultar {
        margin: 7rem 1rem 5rem 1rem;
    }
}

@media screen and (max-width: 665px) {
    .consultar {
        flex-direction: column;
        margin: 7rem 1rem 5rem 1rem;
    }

    .consultar__title {
        white-space: nowrap;
        font-size: 2rem;
        margin: 0 0 1rem 0;
    }
}