.rodape {
    margin: 6rem 0 0 0;
    background: rgb(135, 160, 132);
    background: linear-gradient(343deg, rgba(142,160,132,1) 0%, rgba(183,230,185,1) 100%);
}

.rodape__container {
    padding: 4rem 0;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.rodape__titulo {
    font-weight: bold;
    font-size: 2.5rem;
    font-family: "Rubik", sans-serif;
    color: #1a212f;
}

.rodape__endereco {
    margin: 1rem 0 0 0;
    font-size: 1rem;
    font-family: "Rubik", sans-serif;
    color: #1a212f;
}

.rodape__info {
    font-weight: bold;
    margin: 1rem 0 0 0;
    font-size: 1.1rem;
    font-family: "Rubik", sans-serif;
    color: #1a212f;
}

.rodape__contato {
    margin: 1.5rem 0 0 0;
    font-weight: bold;
    font-size: 2.25rem;
    font-family: "Rubik", sans-serif;
    color: #1a212f;
}

.rodape__telefone {
    text-decoration: underline;
    margin: 1rem 0 0 0;
    font-weight: bold;
    font-size: 2.5rem;
    font-family: "Rubik", sans-serif;
    color: #1a212f;
}

.rodape__telefone:hover {
    text-decoration: underline;
    margin: 1rem 0 0 0;
    font-weight: bold;
    font-size: 2.5rem;
    font-family: "Rubik", sans-serif;
    color: #1b2538;
}

.banner__image {
    border-radius: 1rem;
    width: 26rem;
}

.rodape__mapa {
    cursor: pointer;
    position: relative;
}

.banner__icon {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #fff;
    background:rgb(140, 179, 142);
    padding: 1rem;
    font-size: 1.15rem;
    border-radius: 100%;
}

.banner__copyright {
    font-size: 1rem;
    text-align: center;
    padding: 0 0 2rem 0;
    font-family: "Rubik", sans-serif;
    color: #1b2538;
}

@media screen and (max-width: 1000px) {
    .rodape__container {
        text-align: center;
        flex-direction: column;
        margin: 0rem 1rem;
    }

    .banner__image {
        width: 22.5rem;
        margin: 3rem 2rem;
    }

    .banner__icon {
        position: absolute;
        top: 70px;
        right: 50px;
        color: #fff;
        background:rgb(140, 179, 142);
        padding: 1rem;
        font-size: 1.15rem;
        border-radius: 100%;
    }
}
