.equipe {
    margin: 5rem 0 0 0;
    text-align: center;
    color: #1a212f;
}

.equipe__title {
    margin: 0.5rem 0 0 0;
    font-family: "Lato", sans-serif;
}

.equipe__team {
    margin: 2.5rem auto 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    width: 80%;
    max-width: 1200px;
}

.equipe__people {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin: 1rem;
}

.equipe__image {
    width: 15rem;
    height: auto;
    border-radius: 0.75rem;
}

.equipe__name {
    font-weight: bold;
    font-size: 1.5rem;
    margin: 1rem 0 0.25rem 0;
    font-family: "Rubik", sans-serif;
}

.equipe__description {
    font-family: "Lato", sans-serif;
}

@media screen and (max-width: 1000px) {
    .equipe__team {
        align-items: center;
        flex-direction: column;
    }
}

@media screen and (max-width: 625px) {
    .equipe__title {
        margin: 1rem 2rem 1rem 2rem;
        text-align: center;
    }
}
