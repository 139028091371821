.container {
    margin: 20px auto;
    width: 80%;
    max-width: 800px;
    font-family: Arial, sans-serif;
}

.heading {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    color: #333;
}

.table {
    width: 100%;
    border-collapse: collapse;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
}

.th,
.td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: center;
}

.th {
    background-color: #f4f4f4;
    color: #333;
    font-weight: bold;
}

.td {
    background-color: #fff;
    color: #555;
}

.tr:nth-child(even) .td {
    background-color: #f9f9f9;
}

.tr:hover .td {
    background-color: #f1f1f1;
}
