.title {
    font-size: 3.05rem;
    text-align: center;
    font-family: "Rubik", sans-serif;
    color: #1a212f;
}

@media screen and (max-width: 625px) {
    .title {
        margin: 0 .5rem;
    }
}
