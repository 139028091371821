.veterinario {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 7.5rem 0 7.5rem 0;
}

.veterinario__titulo {
    position: relative;
    z-index: 1;
    margin: 1rem 0 0 0;
}

.veterinario__icon {

}

.veterinario__infos {
    display: flex;
    align-items: center;
    margin: 5rem 0 0 0;
}

.veterinario__divisao {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    z-index: 1;
}

.veterinario__iconePata {
    margin: 0 0 .5rem 0;
}

.veterinario__subtitle {
    position: relative;
    z-index: 1;
    max-width: 27rem;
    font-size: 1.05rem;
    margin: 1rem 0;
    color: #1a212f;
    font-family: "Lato", sans-serif;
}

.veterinario__icone {
    position: absolute;
    top: -6.5rem;
    left: -4.5rem;
    z-index: 0;
}

.veterinario__atividades__imagem {
    width: 32.5rem;
    margin: 0 0 0 5rem;
    border-radius: 1rem;
}

.banner__botao {
    margin: 1rem 0 0 0;
}

@media screen and (max-width: 1000px) {
    .veterinario__infos {
        margin: 1.5rem 0 0 0;
        flex-direction: column;
    }

    .veterinario__atividades__imagem {
        width: 22.5rem;
        margin: 0 auto;
    }

    .veterinario {
        margin: 7rem 0 7rem 0;
        flex-direction: column;
    }

    .veterinario__atividades__swiper {
        margin: 3rem 0 0 0;
    }

    .veterinario__divisao {
        align-items: flex-start;
        margin: 0 1.25rem;
    }

    .banner__botao {
        display: none;
        margin: 1.5rem auto 0 auto;
    }

    .veterinario__subtitle {
        text-align: start;
        margin: 1rem 0;
    }

    .veterinario__iconePata {
        display: none;
    }
}

@media screen and (max-width: 625px) {
    .veterinario__subtitle {
        text-align: justify;
    }
}
