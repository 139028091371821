.banner {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background: rgb(142,160,132);
    background: linear-gradient(343deg, rgba(142,160,132,1) 0%, rgba(183,230,185,1) 100%);

}

.banner__title {
    font-size: 3.5rem;
    font-family: "Rubik", sans-serif;
    color: #1a212f;
}

.banner__botao {
    width: max-content;
}

.banner__subtitle {
    width: 25rem;
    font-size: 1.05rem;
    font-weight: 400;
    margin: 0.95rem 0 2rem 0;
    font-family: "Lato", sans-serif;
    color: #1a212f;
}

.banner__image {
    max-width: 50rem;
}

@media screen and (max-width: 1000px) {
    .banner {
        padding: 8rem 0 0 0;
        flex-direction: column;
    }

    .banner__division {
        display: flex;        
        flex-direction: column;
        justify-content: center;
        text-align: center;
        align-items: center;
        margin: 0 auto;
    }

    .banner__image {
        max-width: 25rem;
    }

    .banner__subtitle {
        width: 20rem;
        font-size: 1rem;
        margin: 1.5rem 1rem;
    }
}
