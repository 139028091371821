.image__gallery {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin: 4rem 0 0 0;
}

.image__gallery__subtitle {
    max-width: 37rem;
    font-size: 1.05rem;
    font-weight: 400;
    margin: 1rem 0;
    font-family: "Lato", sans-serif;
    color: #1a212f;
}

.image__gallery__image {
    width: 15rem;
    height: 100%;
}

.image__gallery__content {
    margin: 3.5rem 0 0 0;
    width: 47rem;
}

@media screen and (max-width: 1000px) {
    .image__gallery__image {
        display: flex;
        flex-direction: column;
    }

    .image__gallery__image {
        width: 8rem;
    }

    .image__gallery__content {
        margin: 3.5rem 2rem 0 2rem;
        width: 25rem;
    }
}

@media screen and (max-width: 625px) {
    .image__gallery__subtitle {
        margin: 1.5rem 1.5rem;

        text-align: center;
    }

    .image__title__division {
        margin: 0 0.5rem;
    }
}

@media screen and (max-width: 585px) {
    .image__gallery__image {
        width: 7rem;
        height: 100%;
    }

    .image__gallery__content {
        margin: 3.5rem 2rem 0 2rem;
        width: 21.5rem;
    }
}
