.header__banner {
    width: 100%;
    height: 88px;
}

.header {
    width: 100%;
    position: fixed;
    top:0;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 0.5rem 0;
    background: #ffffff;
    font-family: "Poppins", sans-serif;
    z-index: 999999;
    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
}

.header__logo {
    width: 4.5rem;
    margin: 0 2rem;
    cursor: pointer;
}

.header__navigation {
    margin: 0 2rem;
}

.header__list {
    display: flex;
    align-items: center;
    transition: 1s cubic-bezier(0.23, 1, 0.32, 1);
}

.header__item {
    margin: 0 0.65rem;
    transition: 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}

.header__divisio__icon {
}

.header__icon {
    margin: .45rem .25rem 0 .25rem;
    padding: .5rem;
    border-radius: 10rem;
    font-size: 1.25rem;
    cursor: pointer;
    color: #ffffff;
    background: rgba(146, 172, 144, 0.767);
    transition: 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}

.header__icon:hover {
    transform: scale(1.125);

}

.header__link {
    font-size: 0.9rem;
    color: #1a212f;
}

.header__link:hover {
    color: #87a084;
}

.header__menu {
    display: none;
    cursor: pointer;
    font-size: 2rem;
}

.header__icons__division {
    display: flex;
    align-items: center;
}

@media screen and (max-width: 1000px) {
    .header {
        justify-content: space-between;
    }

    .header__list {
        position: fixed;
        top: 0;
        right: -100vh;
        height: 100%;
        background: #ffffff;
        width: 15rem;
        padding: 5rem 0 0 0;
        flex-direction: column;
        z-index: 9999;
        box-shadow: -10px -6px 15px -3px rgba(0,0,0,0.1);
    }

    .header__list__open {
        position: fixed;
        top: 0;
        right: 0;
        height: 100%;
        background: #ffffff;
        width: 15rem;
        padding: 5rem 0 0 0;
        flex-direction: column;
        z-index: 9999;
        box-shadow: -10px -6px 15px -3px rgba(0,0,0,0.1);
    }

    .header__link {
        
    }

    .header__item {
        text-align: center;
        margin: 1.25rem 0;
    }

    .header__menu {
        display: block;
        position: fixed;
        top: 1.75rem;
        right: 1rem;
        z-index: 99999;
    }

    .header__icons__division {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: 1rem auto 0 auto;
    }
}