.creche {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.creche__icon {
    width: 5.25rem;
    margin: 0 0 1rem 0;
}

.creche__cards {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    margin: 4rem 0;
}

.creche__card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 20rem;
    height: 23.5rem;
    margin: 0 1rem;
}

.creche__imagem {
    width: 20rem;
    border-radius: 0.5rem;
}

.creche__titulo {
    font-weight: bold;
    font-size: 1.25rem;
    margin: 1rem 0;
    color: #1a212f;
    font-family: "Rubik", sans-serif;
}

.creche__subtitulo {
    font-size: 0.95rem;
    font-family: "Lato", sans-serif;
    color: #1a212f;
}

.creche__button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    font-weight: 800;
    padding: 1rem 2rem;
    border-radius: 5rem;
    width: max-content;
    margin: 1rem 0 0 0;
    border: none;
    color: #ffffff;
    font-family: "Poppins", sans-serif;
    background: rgb(135, 160, 132);
    background: linear-gradient(343deg, rgba(142,160,132,1) 0%, rgba(183,230,185,1) 100%);
    transition: 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}

.creche__button:hover {
    transform: scale(1.025);
}

.creche__atividades {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 0 0 0 0;
}

.creche__atividades__swiper {
    width: 32.5rem;
    margin: 0 5rem 0 0;
}

.creche__atividades__imagem {
    border-radius: 1rem;
}

.creche__atividades__titulo {
    display: flex;
    align-items: flex-start;
}

.creche__atividades__explicacao {
    width: 30rem;
    margin: 1rem 0 0 0;
    font-size: 1.15rem;
    font-family: "Lato", sans-serif;
}

.creche__atividades__destaque {
    font-weight: bold;
    color: #87a084;
}

.creche__precos {
    margin: 7.25rem 0 0 0;
}

.creche_subtitle {
    margin: 0.5rem 0 0 0;
    text-align: center;
    font-size: 1.15rem;
    font-family: "Rubik", sans-serif;
    color: #1a212f;
}

.creche__pacotes {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    align-items: flex-start;
    margin: 3.5rem 0 0 0;
    max-width: 80rem;
}

.creche__pacote {
    font-family: "Lato", sans-serif;
    margin: 0.85rem;
    width: 15rem;
    border-radius: 0.5rem;
    padding: 2rem 1.25rem 1.75rem 1.25rem;
    background: rgb(135, 160, 132);
    background: linear-gradient(343deg, rgba(142,160,132,1) 0%, rgba(183,230,185,1) 100%);
}

.creche__pacote__titulo {
    font-size: 1.25rem;
    font-weight: bold;
    color: #ffffff;
}

.creche__pacote__subtitulo {
    margin: 0.95rem 0 0 0;
    color: #1a212f;
}

.creche__pacote__preco {
    margin: 3rem 0 0 0;
    font-size: 1.25rem;
    font-weight: bold;
    color: #1a212f;
}

.creche__pacote__adendo {
    display: flex;
    align-items: center;
    margin: 2rem 0 0 0;
    font-family: "Lato", sans-serif;
}

.creche__pacote__imagem {
    width: 4rem;
}

.creche__pacote__divisaoAdendo {
    margin: 0 0 0 1.5rem;
}

.creche__pacote__adendoTitulo {
    font-size: 1.15rem;
    font-weight: bold;
}

.creche__pacote__adendoSubtitulo {
    margin: 0.25rem 0 0 0;
}

@media screen and (max-width: 1000px) {
    .creche__cards {
        margin: 1rem 0;
    }

    .creche__card {
        margin: 1rem;
    }

    .creche__atividades {
        margin: 2rem auto 0 auto;
        align-items: center;
        text-align: center;
        flex-direction: column;
    }

    .creche__atividades__swiper {
        width: 22.5rem;
        margin: 0 0 0 0;
    }

    .creche__atividades__titulo {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin: 1rem auto 0 auto;
    }

    .creche__atividades__explicacao {
        width: auto;
        text-align: center;
        margin: 1rem;
    }

    .creche__pacotes {
        justify-content: center;
    }

    .creche__pacote__adendo {
        flex-direction: column;
        margin: 2rem 1rem 0 1rem;
    }

    .creche__pacote__divisaoAdendo {
        margin: 1rem 1rem 1rem 1.5rem;
    }

    .creche__pacote__adendoTitulo {
        font-size: 0.9rem;
        font-weight: bold;
    }
}

@media screen and (max-width: 625px) {
    .creche__atividades__explicacao {
        margin: 1rem 2rem;

        text-align: center;
    }

    .creche__precos {
        margin: 5.25rem 0 0 0;
    }
}
