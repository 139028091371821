.avaliacoes {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.avaliacao__subtitle {
  font-size: 1.15rem;
  margin: 0.5rem 0 2rem 0;
  color: #1a212f;
  text-align: center;
  font-family: "Lato", sans-serif;
}

.avaliacao__container {
  overflow: hidden;
  width: 100%;
  position: relative;
}

.avaliacao__track {
  display: flex;
  width: calc(200%);
  animation: scroll 20s linear infinite alternate;
}

.avaliacao__track:hover {
  animation-play-state: paused;
}

.avaliacao__star {
  color: #ffee00;
  margin: 0 0.25rem 0.25rem 0;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.avaliacao__card {
  flex: 0 0 250px;
  height: auto;
  padding: 1rem 1rem;
  background: rgba(180, 207, 177, 0.767);
  color: #1a212f;
  border-radius: 10px;
  margin: 10px;
}

.avaliacao__card:hover {
  animation-play-state: paused;
}

.avaliacao__text {
  margin: 1.5rem 0 0 0;
  line-height: 1.35rem;
  padding: 0 0.25rem;
  font-family: "Rubik", sans-serif;
}

.avaliacao__user {
  font-family: "Lato", sans-serif;
}

@media (max-width: 768px) {
  .avaliacao__container {
    overflow: hidden;
    position: relative;
    width: 100%; 
  }
  
  .avaliacao__track {
    display: flex;
    width: calc(200%); 
    animation: scroll 20s linear infinite; 
  }
  
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(-300%);
    }
    100% {
      transform: translateX(0); 
    }
  }
  
  @media (max-width: 768px) {
    .avaliacao__track {
      animation-duration: 50s;
    }
  }
  
  .avaliacao__card {
    flex: 0 0 250px;
    height: auto;
    padding: 1rem;
    background: rgba(180, 207, 177, 0.767);
    color: #1a212f;
    border-radius: 10px;
    margin: 10px;
  }
}
