@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* font-family: "Lato", sans-serif; */
/* font-family: "Rubik", sans-serif; */
/* font-family: "Poppins", sans-serif; */

* {
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
}

body, 
html {
    overflow-x: hidden;
}

body {
    background: #ffffff;
    overflow-x: hidden;
}
