.redes {
    width: max-content;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    margin: 5.5rem auto 0 auto;
}

.redes__container {
    display: flex;
    margin: 2rem 0 0 0;
    overflow-y: hidden;
}

.redes__division {
    width: 6.5rem;
    border-radius: 0.5rem;
    padding: 1rem;
    margin: 0.5rem;
    cursor: pointer;
    transition: 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}

.redes__division:hover {
    transform: scale(1.025);
}

.redes__instagram {
    background: #e1306c;
}

.redes__tiktok {
    background: #000000;
}

.redes__youtube {
    background: #c4302b;
}

.redes__icon {
    font-size: 1.5rem;
    color: #ffffff;
}

.redes__name {
    margin: 6.15rem 0 0 0;
    font-weight: bold;
    font-family: "Rubik", sans-serif;
    color: #ffffff;
}

@media screen and (max-width: 1000px) {
    .redes {
        width: 25rem;
        align-items: center;
    }
}

@media screen and (max-width: 700px) {
    .redes__division {
        width: 4.75rem;
        border-radius: 0.5rem;
        padding: 1rem;
        margin: 0.5rem;
        cursor: pointer;
        transition: 0.3s cubic-bezier(0.23, 1, 0.32, 1);
    }
}
