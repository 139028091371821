.button {
    width: 12.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    font-weight: 800;
    padding: .35rem .6rem .35rem 1.25rem;
    border-radius: 5rem;
    border: none;
    color: #1a212f;
    background: #ffffff;
    font-family: "Lato", sans-serif;
    transition: 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}

.button:hover {
    transform: scale(1.025);
}

.button__green {
    background: rgb(135, 160, 132);
    background: linear-gradient(343deg, rgba(142,160,132,1) 0%, rgba(183,230,185,1) 100%);
}

.button_text {
    margin: 0 0 0 .5rem;
}

.button__icon {
    font-size: 1.25rem;
    padding: .5rem;
    border-radius: 100%;
    color: #ffffff;
    background: #87a084;
}
